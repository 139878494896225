import { userRoles } from 'utils/constants/roles';

import { appModules } from './appModuleConfig';

const { traveler, travel_admin, travel_itilite_admin } = userRoles;

export const roleBasedPermission = {
	[appModules.createTrip]: [traveler, travel_admin, travel_itilite_admin],
	[appModules.carServices]: [traveler, travel_admin, travel_itilite_admin],
	[appModules.home]: [traveler, travel_itilite_admin],
};
