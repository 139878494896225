import { ParallaxProvider } from 'react-scroll-parallax';

import { Toast } from 'components/atoms';

import AppRoutes from './routes/Routes';

import './App.css';
import './theming/__globals.scss';
import 'react-toastify/dist/ReactToastify.css';

function App() {
	console.log('App.js');

	return (
		<>
			<ParallaxProvider>
				<AppRoutes />
			</ParallaxProvider>
			<Toast />
		</>
	);
}

export default App;
