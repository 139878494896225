let servicesUSMUrl = process.env.REACT_APP_USM_URL;

const AUTH = {
	FETCH_REFRESH_TOKEN: `${servicesUSMUrl}/account/auth/token/refresh/`,
	FETCH_VERIFY_TOKEN: `${servicesUSMUrl}/account/auth/token/verify/`,
	VERIFY_TOKEN: `${servicesUSMUrl}/api/v1/user-management/user/authorization`,
	NAVBAR: `${servicesUSMUrl}/api/v1/user-management/navbar/company/`,
	COUNTRY_CODE: `/api/v1/user-management/country-code-details/`,
	LOGOUT: `${servicesUSMUrl}/account/auth/logout/`,
};
const TRIP_RESULTS = {
	LEG_DETAILS_LABMDA: `/v1/trip`,
	LEG_DETAILS: `/api/v1/package/trip`,
	REFETCH_FARE: `/v1/steplambda`,
	SELECT_FARE: `/v1/select-fare`,
	ROOM_DETAILS: `/v1/hotel-room-details`,
	PRICELINE: `/v1/more-fare-cal`,
	ROOM_CANCELLATION_DETAILS: `/v1/room-cancellation-details`,
	RESULTS: (mode, leg_id, event, ngs_content) =>
		ngs_content === 1 && mode === 'flight'
			? `/api/v2/search/${mode}/request/${leg_id}/${event}`
			: `/api/v1/search/${mode}/request/${leg_id}/${event}`,
	COLLEAGUE_INFO: (link) => `/api/v1/package/colleague-feedback/${link}`,
};
const CONFIRMATION = {
	CONSOLIDATED_DATA: `/v1/confirmation`,
	SEAT_SELECTION: `/v1/gordian-seat-selection`,
	CONFIRMATION_PROCEED: `/v1/confirmation?action=proceed`,
	PAYMENT: `/v1/payment`,
	AIRLINE_MEMBERSHIPS: '/api/v1/autocomplete/airline-data',
	PREPARE_CONFIRMATION: '/v1/helper',
	MEMBERSHIP_LIST: '/api/v1/user-management/loyalty-program/',
};

const CANCEL_BOOKING = {
	CANCELLATION_DETAILS: `/v1/trip-modify/cancellation-details`,
	SUMMARY: `/v1/trip-modify/cancellation-request`,
};
const RESCHEDULE = {
	DETAILS: `/v1/trip-modify/booking-details`,
	TIME_WINDOW: `/v1/trip-time-window`,
	MODIFICATION: `/v1/trip-modify/reschedule-request`,
	ACTUAL_BOOKING: `/v1/booked-details`,
	SUMMARY: '/v1/trip-modify/reschedule-request',
};
const PAYMENT = {
	FRAUD_CHECK: `/api/v1/payment/fraud_element`,
};
const APPROVALS = {
	TRIP_INFO: '/api/v1/trip/details',
	TRIP_INFO_LOGGED: '/api/v1/trip/dashboard/details',
	FARE_QUOTE: 'api/v1/trip/farequote',
	FARE_QUOTE_LOGGED: 'api/v1/trip/dashboard/farequote',
};
const CAR_SERVICES = {
	CAR_RESULTS: '/api/v1/cab/rental_car/fetch_car_package_details',
	CAR_FARE_QUOTE_CHECK: '/api/v1/cab/rental_car/farequote_check',
	CAR_FARE_QUOTE: '/api/v1/cab/rental_car/fetch_car_farequote_details',
	CAR_BOOKING_CHECK: '/api/v1/cab/rental_car/booking_check',
	CAR_BOOKING_DETAILS: '/api/v1/cab/rental_car/fetch_car_booking_details',
};
const HOME_DASHBOARD = {
	getList: (role, dashboard_type, tab) =>
		`/api/v1/${role}/dashboard/${dashboard_type}/itinerary/${tab}`,
	getContinue: (role, dashboard_type) =>
		`/api/v1/${role}/dashboard/${dashboard_type}/itinerary/continue`,
	deleteTrip: (trip_id, type, car_id = '') => type === 'car' ? `/api/v1/dashboard/service/delete/${trip_id}?car_id=${car_id}` : `/api/v1/dashboard/service/delete/${trip_id}`,
};
const HOME_ITINERARY = {
	getDetails: (dashboard_type, trip_id) =>
		`/api/v1/${dashboard_type}/itinerary/${trip_id}`,
	cancelCar: (carId = '') => `/api/v2/cab/rental_car/cancel_booking`,
	downloadInvoiceAndVouchers: (tripId = '', type) => `/api/v1/dashboard/service/${type}/${tripId}`,
	roomDetails: () =>  `/v1/hotel-room-details`,
};
export const apiUrls = {
	AUTH,
	TRIP_RESULTS,
	CONFIRMATION,
	CANCEL_BOOKING,
	RESCHEDULE,
	PAYMENT,
	APPROVALS,
	CAR_SERVICES,
	HOME_DASHBOARD,
	HOME_ITINERARY,
};
