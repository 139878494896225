import * as React from 'react';
import { Rate } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import classes from './Rating.module.scss';

const RatingComp = (props) => {
	const {
		defaultValue = 0,
		allowHalf = true,
		className = '',
		disabled = true,
		size = 16,
		...rest
	} = props;
	return (
		<Rate
			className={clsx(className, classes.starRating)}
			allowHalf={allowHalf}
			defaultValue={defaultValue}
			disabled={disabled}
			style={{ fontSize: size, color: '#363E4F' }}
			{...rest}
		/>
	);
};

RatingComp.propTypes = {
	className: PropTypes.string,
	allowHalf: PropTypes.bool,
	defaultValue: PropTypes.number,
	size: PropTypes.number,
};

export const Rating = React.memo(RatingComp);
