import * as React from 'react';

export const Invoice = React.memo(
	({ size = 16, color = '#363E4F', className, ...rest }) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={size}
				height={size}
				fill="none"
				viewBox="0 0 16 16"
				className={className}
				{...rest}
			>
				<path d="M3.75 2C2.7835 2 2 2.7835 2 3.75V11.5C2 12.8807 3.11929 14 4.5 14H11.5C12.8807 14 14 12.8807 14 11.5V10H11V3.75C11 2.7835 10.2165 2 9.25 2H3.75ZM11 11H13V11.5C13 12.3284 12.3284 13 11.5 13H11V11ZM3 3.75C3 3.33579 3.33579 3 3.75 3H9.25C9.66421 3 10 3.33579 10 3.75V13H4.5C3.67157 13 3 12.3284 3 11.5V3.75ZM4.5 5.5C4.5 5.22386 4.72386 5 5 5H8C8.27614 5 8.5 5.22386 8.5 5.5C8.5 5.77614 8.27614 6 8 6H5C4.72386 6 4.5 5.77614 4.5 5.5ZM4.5 8C4.5 7.72386 4.72386 7.5 5 7.5H8C8.27614 7.5 8.5 7.72386 8.5 8C8.5 8.27614 8.27614 8.5 8 8.5H5C4.72386 8.5 4.5 8.27614 4.5 8ZM5 10C4.72386 10 4.5 10.2239 4.5 10.5C4.5 10.7761 4.72386 11 5 11H6.5C6.77614 11 7 10.7761 7 10.5C7 10.2239 6.77614 10 6.5 10H5Z" fill={color} />
			</svg>
		);
	}
);

Invoice.displayName = 'Invoice';
