import * as React from 'react';

export const OneTimeVirtualCard = React.memo(
	({ size = 24, className, ...rest }) => {
		return (
			<svg
				width={size}
				height={size}
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className={className}
			>
				<rect
					x="0.4"
					y="4.4"
					width="23.2"
					height="15.2"
					rx="2"
					fill="url(#paint0_radial_1138_39450)"
				/>
				<rect
					x="0.4"
					y="4.4"
					width="23.2"
					height="15.2"
					rx="2"
					stroke="#D3D8DF"
					stroke-width="0.8"
				/>
				<circle cx="4.4" cy="7.60039" r="1.2" fill="#D3D8DF" />
				<rect
					x="6.39999"
					y="7.19922"
					width="4.8"
					height="0.8"
					rx="0.4"
					fill="#D3D8DF"
				/>
				<circle cx="17.6" cy="16.0004" r="1.6" fill="#EB001B" />
				<circle cx="20" cy="16.0004" r="1.6" fill="#F79E1B" />
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M18.8 17.06C19.049 16.778 19.2 16.4075 19.2 16.0017C19.2 15.5959 19.049 15.2254 18.8 14.9434C18.5511 15.2254 18.4 15.5959 18.4 16.0017C18.4 16.4075 18.5511 16.778 18.8 17.06Z"
					fill="#FF5F00"
				/>
				<defs>
					<radialGradient
						id="paint0_radial_1138_39450"
						cx="0"
						cy="0"
						r="1"
						gradientUnits="userSpaceOnUse"
						gradientTransform="translate(0 4) rotate(33.6901) scale(28.8444 43.2666)"
					>
						<stop stop-color="white" />
						<stop offset="1" stop-color="#F9FAFB" />
					</radialGradient>
				</defs>
			</svg>
		);
	}
);

OneTimeVirtualCard.displayName = 'OneTimeVirtualCard';
