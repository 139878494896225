import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	currentTrip: null,
	itineraryData: null,
	propertyDataByLeg: null
};
export const ItineraryData = createSlice({
	name: 'itineraryDataSlice',
	initialState,
	reducers: {
		setCurrentTripData: (state, action) => {
			state.currentTrip = action.payload;
		},
		setItineraryData: (state, action) => {
			state.itineraryData = action.payload;
		},
		setPropertyData: (state, action) => {
			state.propertyDataByLeg = action.payload;
		}
	},
});

export const {
	setItineraryData,
	setCurrentTripData,
	setPropertyData
} = ItineraryData.actions;

export default ItineraryData.reducer;
