import * as React from 'react';

export const Bed = React.memo(({ size = 16, color = '#6B7280', className = '', ...rest }) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 14 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
	>
		<path
			d="M2 2.5C2 1.11929 3.11929 0 4.5 0H9.5C10.8807 0 12 1.11929 12 2.5V4.05001C13.1411 4.28164 14 5.29052 14 6.5V11.5C14 11.7761 13.7761 12 13.5 12C13.2239 12 13 11.7761 13 11.5V9H1V11.5C1 11.7761 0.776142 12 0.5 12C0.223858 12 0 11.7761 0 11.5V6.5C0 5.29052 0.85888 4.28164 2 4.05001V2.5ZM1 8H13V6.5C13 5.67157 12.3284 5 11.5 5H2.5C1.67157 5 1 5.67157 1 6.5V8ZM11 2.5C11 1.67157 10.3284 1 9.5 1H4.5C3.67157 1 3 1.67157 3 2.5V4H4V3.5C4 3.22386 4.22386 3 4.5 3H6C6.27614 3 6.5 3.22386 6.5 3.5V4H7.5V3.5C7.5 3.22386 7.72386 3 8 3H9.5C9.77614 3 10 3.22386 10 3.5V4H11V2.5Z"
			fill={color}
			{...rest}
		/>
	</svg>
));

Bed.displayName = 'Bed';
